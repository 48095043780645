@import '~app/variables';

$color-list-1: #f0f0f0;
$color-list-2: $color-alabaster;
$color-list-3: #d8dbdf;

.section {
  display: flex;
  flex-direction: column;
  min-height: 45px;
  color: $color-grey;

  .iconCaret {
    width: 9px;
    height: 16px;

    use {
      fill: $color-grey-light;
    }
  }

  > .header {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 8px 0;
    background-color: #2b2c33;
    justify-content: space-between;

    .toggle {
      $size: 19px;

      width: $size;
      height: $size;
      position: relative;
      top: 0;
      right: -5px;
      cursor: pointer;
      border: none;
      background: transparent;
      padding: 0;

      svg {
        fill: #fff;
        margin-top: 2px;
        width: 10px;
        height: 10px;
      }
    }

    .name {
      display: flex;
      font-size: 14px;
      line-height: 21px;
      margin-left: 8px;
      pointer-events: none;
      flex: 1;

      .size {
        display: inline-block;
        border-radius: 4px;
        padding: 0 4px 0 3px;
        margin: 2px 0 0 6px;
        background-color: #f16722;
        font-size: 10px;
        height: 14px;
        line-height: 13px;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-right: 8px;

        use {
          fill: $color-grey-light;
        }

        &.iconSearch {
          width: 20px;
        }

        &:hover,
        &.active {
          use {
            fill: #fff;
          }
        }
      }
    }

    &.open {
      .toggle {
        svg {
          margin-top: 0;
          width: 10px;
          height: 15px;
        }
      }
    }

    &.dropping {
      background-color: #ffd64b;
    }
  }

  .search {
    display: none;
    padding: 6px 12px;
    background-color: $color-list-2;
    border-bottom: 1px solid $color-list-3;

    .row {
      display: flex;
      align-items: center;

      .label {
        width: 80px;
        padding-right: 4px;
        text-align: right;
      }

      :last-child {
        flex: 1;
      }

      .range {
        display: flex;

        > div {
          &:not(.sep) {
            flex: 1;
          }
        }

        .sep {
          margin: 6px 4px;
        }
      }

      input {
        border-color: $color-list-3;
        border-radius: 6px;
      }

      + .row {
        margin-top: 5px;
      }
    }

    .clear {
      text-align: center;
      margin-top: 5px;

      button {
        padding: 2px;
        width: 102px;
        border-color: $color-list-3;
      }
    }
  }

  .body {
    overflow-x: hidden;
    overflow-y: auto;
    color: $color-grey-1;
    display: none;

    .children {
      padding: 8px;
      border-bottom: 1px solid $color-list-3;
    }

    .node {
      position: relative;

      svg {
        position: absolute;
        top: 3px;
        left: 7px;
        width: 16px;
        height: 16px;

        &.heart {
          fill: red;
        }
      }

      .header {
        height: 24px;
        display: flex;
        align-items: center;
        position: relative;

        .label {
          margin-left: 30px;
        }

        .actions {
          display: none;
          position: absolute;
          right: 0;
          top: 0;

          svg {
            position: static;
            width: 14px;
            margin-right: 4px;
            cursor: pointer;
          }
        }

        svg {
          top: 8px;
          left: 8px;
        }

        > :not(.actions) {
          pointer-events: none;
        }

        &:not(.empty) {
          cursor: pointer;
        }

        &.folderHeader {
          font-weight: bold;

          svg {
            top: 4px;
          }

          &:hover .actions {
            display: block;
          }

          &:not(.empty) svg {
            fill: #266bc9;
          }

          .actions {
            svg {
              fill: $color-grey-light;

              &:hover svg {
                fill: #266bc9;
              }
            }
          }
        }

        &.groupHeader {
          height: 30px;
          background-color: $color-list-2;
          border-top: 1px solid $color-list-3;
          border-bottom: 1px none $color-list-3;

          .iconCaret {
            width: 10px;
            height: 15px;
            left: 12px;
          }

          &:first-child {
            border-top-style: none;
          }

          &.open {
            .iconCaret {
              transform: rotate(90deg);
              margin-top: 2px;
            }

            &:not(.item) + .node {
              //margin-top: 12px;
            }
          }

          &.open,
          &:last-child {
            border-bottom-style: solid;
          }
        }
      }

      .childContainer {
        position: relative;
        overflow: hidden;

        .childLiner {
          display: none;
          position: absolute;
          z-index: 10;
          height: 100%;
          top: -12px;
          left: 15px;
          border-left: 1px dotted $color-grey-light;
        }
      }

      .nodeLiner {
        display: none;
        position: absolute;
        z-index: 10;
        top: 12px;
        left: 16px;
        width: 6px;
        border-top: 1px dotted $color-grey-light;
      }

      .nodeMask {
        display: none;
        position: absolute;
        z-index: 11;
        top: 13px;
        left: -8px;
        width: 6px;
        height: 100%;
        background-color: white;
      }

      &.group {}

      &.folder {
        > .childContainer {
          > .childLiner {
            display: block;
          }

          > * > .nodeLiner {
            display: block;
          }

          > .folder {
            margin-left: 20px;
            position: relative;

            > .nodeLiner {
              left: -4px;
            }

            &:last-child > .nodeMask {
              display: block;
            }
          }
        }
      }

      &.item {
        .itemInner {
          height: 24px;
          display: flex;
          align-items: center;
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;
          line-height: 15px;
          cursor: pointer;
          color: $color-grey-1;

          > * {
            pointer-events: none;
          }

          .label {
            display: flex;
            width: 100%;
            padding-left: 30px;

            .labelName {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .labelQuantity {
              margin-left: 4px;
              margin-right: 2px;
            }
          }

          &:not(.dropping) {
            &:hover, &.selected {
              background-color: $color-light-blue;
            }
          }

          &.dropping {
            background-color: $color-yellow;
          }

          &.noDrop {
            //cursor: no-drop; * Cursor doesn't seem to change when dragging.
          }
        }
      }
    }
  }

  &.open {
    height: 100vh;

    .body {
      display: block;
    }
  }

  &:not(.open) {
    .actions {
      display: none;
    }
  }

  &.searchMode {
    .search {
      display: unset;
    }
  }
}
