@import "./../../app/variables.scss";
@import '~app/mixins';

.container {
  padding: 0 10px;
  margin: 5px 0;
  .section {
    width: 100%;
    height: 10px;
    background-color: $color-white;
    border-radius: 6px;
    border: solid 1px $color-teal;
    overflow: hidden;
    .progressBar {
      height: 100%;
      border-radius: 6px;
      background-color: $color-teal;
      transition: width 0.3s ease-in-out;
    }
  }
}
