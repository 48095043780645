@import '~app/mixins.scss';

.warning {
  border-radius: 5px;
  border: 1px solid $color-red;
  color: $color-red;
  background-color: #fbcbc0;
  margin: 15px 0;
  padding: 6px;
  font-size: 13px;

  > div {
    + div {
      margin-top: 6px;
    }
  }

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}
