@import '~app/variables';

.info {
  position: relative;
  display: inline-block;

  &:hover .caption {
    visibility: visible;
    opacity: 1;
  }
}

.icon {
  $size: 13px;

  width: $size;
  height: $size;
  line-height: $size;
  background-color: $color-grey;
  color: $color-white;
  display: inline-block;
  border-radius: 50%;
  border: none;
  text-align: center;
  font-size: 9px;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
}

.caption {
  background: $color-grey-1;
  color: $color-white;
  visibility: hidden;
  width: 120px;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity $transition-delay;
  font-size: 12px;
  text-align: center;

  &:empty {
    display: none;
  }
}
