@use 'sass:math';
.p_20 {
  padding: 20px;
}
.px_16{
  padding: 0 16px;
}

.clsActiveOption {
  background-color: #f0f0f0; 
  color: #333;             
  font-weight: bold;       
  cursor: pointer;         
}

.flexBoxWrap {
  display: flex;
  flex-wrap: wrap;
}

.align_items_center {
  align-items: center;
}

.align_items_center {
  align-items: center;
}

.mb_0 {
  margin-bottom: 0px !important;
}

.mt_0{
  margin-top: 0px !important;
}

.pb_28 {
  padding-bottom: 28px;
}
.w_100{
  width: 100%;
}

.toggleBorder{
  padding: 8px;
  background-color: #F3F5F8;
  border-radius: 30px;
}

.highlightOrange{
  background-color: #F16722 !important;
}

.selctAllBtn {
  color: #66707d;
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 14px;
  background-color: #fff;
  border: solid 1px #dfe1e4;
  border-radius: 999px;
  padding: 7px 12px !important;
  padding-left: 35px !important;
  position: relative;
  margin-left: 20px;

  &:hover {
    background-color: #dfe1e4;
    color: #66707d !important;

    &.unSelctCrle {
      border-color: #fff;
    }
  }

  &:focus,
  &:active,
  &.active {
    background-color: #bcc1c7;
    color: #66707d !important;

    &.unSelctCrle {
      border-color: #fff;
    }
  }

  &.checkSelctedBtn {
    color: #fff;
    background-color: #128292;

    .checkSelctCrle {
      background-color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: #0e6673;
      color: #fff !important;

      .checkSelctCrle {
        .svgCheckIcon {
          svg {
            path {
              fill: #0e6673;
            }
          }
        }
      }
    }

    &:focus,
    &:active,
    &.active {
      background-color: #0a464f;
      color: #fff !important;

      .checkSelctCrle {
        .svgCheckIcon {
          svg {
            path {
              fill: #0a464f;
            }
          }
        }
      }
    }
  }

  .selctAllCrle {
    width: 20px;
    height: 20px;
    border-radius: 999px;
    display: inline-block;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);

    &.minSelctCrle {
      background-color: #128292;

      &::after {
        content: "";
        width: 12px;
        height: 2px;
        border-radius: 999px;
        background-color: #fff;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.unSelctCrle {
      background-color: #fff;
      border: solid 1px #dfe1e4;
    }
  }
}

.clsGroupBtn {
  padding-top: 12px;

  .clsBtnList {
    color: #66707d;
    font-size: 14px;
    padding: 7px 15px !important;
    line-height: 100%;
    font-weight: 500 !important;
    background-color: #fff;
    border: solid 1px #dfe1e4;
    border-radius: 999px;
    margin-right: 8px;
    margin-bottom: 8px;
    margin-left: 0;

    &:last-child {
      margin-right: 0;
    }

    &.clsActive {
      background-color: #128292;
      color: #fff;
      &:hover,
      &:focus{
        color: #fff;
      }
    }

    &:hover,
    &:focus{
      color: #66707d;
    }

    &:hover,
    &:focus{
      color: #66707d;
    }
  }

  .clsShowAll {
    color: #128292;
    font-weight: 600 !important;
    background: transparent;
    border-radius: 999px;
    margin-left: 0;
    padding: 2px 15px !important;

    &:hover {
      background: #dfe1e4;
      color: #128292;
    }

    &:focus,
    &:active,
    &.active {
      background: #bcc1c7;
      color: #128292;
    }
  }
}

// toggleList

.clsBgWhite {
  background: white;
  padding: 4px;
  border-radius: 10px;
}

.clsBgGray {
  background: #f3f5f8;
  padding: 4px;
  border-radius: 20px;
}

.clsSubHeading {
  font-size: 16px;
  line-height: 16px;
  color: #3c4654;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;
}

.clsToggleTab {
  outline: solid 1px #dfe1e4;
  border-radius: 999px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0px;
  padding: 3px;
  li {
    flex: 1 1 auto;
    text-align: center;
    margin-right: 2px;
    &:last-child {
      margin-left: 0;
    }
    span {
      color: #66707d;
      font-weight: 600;
      font-size: 16px;
      border: 0 !important;
      border-radius: 999px;
      padding: 5px 22px;
      cursor: pointer;
      outline: none !important;
      display: inline-flex;
      height: 38px;
      align-items: center;
      justify-content: center;
      width: 100%;
      &:hover {
        background-color: #dfe1e4;
      }
      &:focus {
        background-color: #bcc1c7;
      }
    }
    &.active {
      span {
        background-color: #128292;
        color: #fff;
      }
    }
  }
  &.clsSmallToggle {
    li {
      span {
        height: 30px;
      }
    }
  }
}

// Auto search

.flexBoxNowrap {
  display: flex;
  flex-wrap: nowrap;
}



.clsFilterSelect {
  width: 100%;
  .clsSearchBox {
    position: relative;
    margin-right: 2px;
    width: 100%;
    .clsSearchInput { 
      border: 0;
      box-shadow: 0px 0px 0px 1px #dfe1e4 inset; 
      color: #3c4654;  
      padding: 10px 15px;
      height: 44px;
      border-radius: 0px;
      width: 100%;  
      background: #fff; 
      transition: all 150ms; 
      font-size: 16px;
      font-weight: 500;
      z-index: 1;
      position: relative;
      overflow: hidden;
  
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
  
      &::placeholder {
        color: #99a0aa;
      }  

      &:focus { 
        box-shadow: 0px 0px 0px 2px #128292 inset; 
      }  

      &.clsSearchInputActive{
        box-shadow: 0px 0px 0px 2px #128292 inset; 
      }
    }

    &:first-child {
      .clsSearchInput{
       border-top-left-radius: 12px;
       border-bottom-left-radius:12px ;
        &:focus {
          border-bottom-left-radius: 0px;
        }
        &.clsSearchInputActive{
          border-bottom-left-radius: 0px;
        }
      }
    }

    &:last-child {
      margin-right: 0px;
      .clsSearchInput{
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;  
        &:focus {
          border-bottom-right-radius: 0px;
        }
        &.clsSearchInputActive{
          border-bottom-right-radius: 0px;
        }
      }
    }
    
    .clsAutoSearchArrow{
      position: absolute;
      right: 12px;
      top: 50%;
      z-index: 4;
      transform: translateY(-50%);
        .svgArrowIcon{
          display: inline-flex;
          width: 15px;
          height: 9px;
          div{
            display: inline-flex;
          }
      
          svg{
            width: 15px;
            height: 9px;
            flex: 0 0 15px;
            // path{
            //   fill: #99A0AA;
            // }
          }
        }

        .clsAutoSearchRotate{ 
          .svgArrowIcon{
            display: inline-flex;
            width: 15px;
            height: 9px;
            transform: translateY(-50%);
            div{
              display: inline-flex;
            }
            svg{
              width: 15px;
              height: 9px;
              flex: 0 0 15px;
              path{
                fill: #3C4654;
              }
            }
          }
        }
    }

    
  
    .clsBtnClear {
      display: block;
      width: 26px;
      height: 26px;
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 30;
      padding: 5px;
      border-radius: 999px;
  
      .clsIconClear {
        width: 12px;
        height: 12px;
  
        .clsIconColor {
          fill: #99a0aa;
          stroke: #99a0aa;
        }
      }
  
      &:hover {
        background-color: #99a0aa;
  
        .clsIconClear {
          .clsIconColor {
            fill: #fff;
            stroke: #fff;
          }
        }
      }
  
      &:focus,
      &.active {
        background-color: #66707d;
  
        .clsIconClear {
          .clsIconColor {
            fill: #fff;
            stroke: #fff;
          }
        }
      }
    }
  .clsAutoSearchDropdown{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 2px solid #128292;
    border-top: 0;
    border-radius: 0 0 12px 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    // padding-right: 6px;
    /* Enable scrolling */
    z-index: 999;
  }
    .clsAutoSearchList { 
      padding: 6px;
      margin: 0;
      max-height: 150px;
      overflow-y: auto;
      list-style: none;

      &::-webkit-scrollbar {
        width: 12px;
        min-height: 3px;
      }

      &::-webkit-scrollbar-track {
        margin: 16px;
      }

      &::-webkit-scrollbar-thumb {  
        border: 4px solid transparent;    
        border-radius: 999px;
        background-clip: padding-box;
        background-color: #DFE1E4;  
      }
      
      // border: solid 1px #128292;
  
      // .clsAutoSearchLoading {
      //   margin: 0;
      //   padding: 0;
      //   padding-right: 6px;
      //   max-height: 150px;
      //   overflow-y: auto;
      //   list-style: none;
      // }
  
      // .clsAutoSearchResult {
      //   margin: 0;
      //   padding: 0;
      //   padding-right: 6px;
      //   max-height: 150px;
      //   overflow-y: auto;
      //   list-style: none;
      // }
    }
  
    .clsAutoSearchOption {
      padding: 7px 16px;
      font-size: 16px;
      color: #3c4654;
      font-weight: 600 !important;
      cursor: pointer; 
      border-radius: 8px;
  
      &:hover {
        background-color: #DFE1E4;
      }
      &.active,
      &:focus,
      &.activeSuggestion {
        background-color: #128292;
        color: #fff;
      }
    }
  } 

  // option:not(:checked) {
  //   color: #3c4654;
  //   border: solid 1px #128292;
  //   background: transparent;
  // }

  .form_control{
    border: solid 1px #dfe1e4;
    color: #3c4654; 
    box-shadow: none;
    padding: 10px 15px;
    height: 44px;
    border-radius: 12px;
    width: 100%;  
    background: #fff; 
    transition: all 150ms; 
    font-size: 16px;
    font-weight: 500;
    z-index: 1;  
    &::placeholder {
      color: #99a0aa;
    }  

    &:focus {
      border-color: #128292; 
    } 
  }

}

.range {
  display: flex;

  > div {
    &:not(.sep) {
      flex: 1;
    }
  }

  .sep {
    margin: 6px 4px;
  }
}

.icon {
  $size: 18px;

  position: absolute;
  top: 50%;
  right: 4px;
  height: $size;
  width: $size;
  margin-top: math.div(-$size, 2);
  pointer-events: none;
  fill: #333;
}


.clsCenterCol{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  .clsCol{
    padding: 0 6px;
    flex: 0 0 auto;
    width: 46%;

    &:first-child{
      padding-right: 0px;
      padding-left: 15px;
    }
    &:last-child{
      padding-left: 0px;
      padding-right: 15px;
    }
    &:nth-of-type(2){
      flex: 1 0 auto;
      width: 8%;
    }
    .clsColTxt{
      font-size: 14px;
      color: #66707D;
      font-weight: 600;
      margin-bottom: 0;
      text-align: center;
    }
  }
}

.clsDatePicker {
  
  .clsDatePickerBox {
    position: relative;
    margin-right: 2px;
    width: 100%; 

    .clsDatePickerInput { 
      border: solid 1px #dfe1e4;
      color: #3c4654; 
      box-shadow: none;
      padding: 10px 15px;
      height: 44px;
      border-radius: 0px;
      width: 100%;  
      background: #fff; 
      transition: all 150ms; 
      font-size: 16px;
      font-weight: 500;
      z-index: 1;
      position: relative;
      overflow: hidden;
  
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
  
      &::placeholder {
        color: #99a0aa;
      }  

      &:focus {
        border-color: #128292; 
      }  
    }

    &:first-child {
      .clsDatePickerInput{
       border-top-left-radius: 12px;
       border-bottom-left-radius:12px ;
        &:focus {
          border-bottom-left-radius: 0px;
        }
      }
    }

    &:last-child {
      margin-right: 0px;
      .clsDatePickerInput{
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;  
        &:focus {
          border-bottom-right-radius: 0px;
        }
      }
    } 
  }  
}