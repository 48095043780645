@import '~app/variables';

.voicemail {
  width: 100%;
}

.header,
.left,
.right {
  display: flex;
  align-items: center;
}

.left {
  width: 350px !important;
}

.header {
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 30px 20px 40px;
}

.instructions {
  font-size: 15px;
  color: #666;
  margin: 0 0 25px 20px;
}

.title {
  color: $color-blue-dark;
  font-size: 24px;
  line-height: 32px;
}

.btn {
  min-width: 100px;
  margin: 0 4px;

  &:last-child {
    margin-right: 0;
  }
}

.autoBtn {
  width: auto;
}

.blueLink {
  margin-top: 6px;
}

.body {
  padding-bottom: 35px;
  height: calc(100vh - 105px);
  overflow-x: hidden;
  overflow-y: auto;
}

.panel {
  width: 550px;
  padding: 25px;
  margin-left: 20px;
}

.buttons {
  button {
    min-width: unset;
    margin: 0 10px 0 0;
  }
}

.bottomButtons {
  margin-top: 30px;
}

.edit {
  margin-top: 8px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;

  &.active {
    max-height: 1000px;
    transition: max-height 0.3s ease-in;
  }
}

.editContainer {
  position: relative;
}

.section {
  margin-bottom: 20px;

  label {
    width: 100%;

    > div {
      display: flex;
      justify-content: space-between;

      button {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.callTime {
  margin-bottom: 6px;
  color: $color-blue-dark;
  font-size: 14px;

  label {
    display: inline-block;
    width: 110px;
  }

  .time {
    width: 90px;
    display: inline-block;
    margin: 0 5px;
  }

  .status {
    color: $color-green;
    display: inline-block;
    margin-left: 8px;
  }

  &.noDelivery {
    .status {
      color: $color-red;
      font-style: italic;
    }

    select {
      background-color: #fbcbc0;
    }
  }
}

.warning {
  width: 465px;
  text-align: justify;
}

.recordingLine {
  color: $color-grey;
  font-size: 14px;
  margin-top: 5px;

  span {
    font-weight: bold;
  }
}

.radioButtons {
  display: flex;
  margin-bottom: 8px;

  label {
    margin-right: 25px;
  }
}
