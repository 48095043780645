@use 'sass:math';

@import "~app/mixins";

body {
  font-family: $font-inter !important;

  input,
  optgroup,
  button,
  textarea,
  select,
  a {
    font-family: $font-inter !important;
  }
}

// ::-webkit-scrollbar {
//   width: 6px;
//   height: 6px;
// }

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 999px;
  border-radius: 999px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 999px;
  border-radius: 999px;
  background: #dfe1e4;
}

.flexBoxWrap {
  display: flex;
  flex-wrap: wrap;
}

.flexBoxNowrap {
  display: flex;
  flex-wrap: nowrap;
}

.align_items_start {
  align-items: start;
}

.align_items_center {
  align-items: center;
}

.align_items_end {
  align-items: flex-end;
}

.justify_content_start {
  justify-content: flex-start;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_between {
  justify-content: space-between;
}

.justify_content_end {
  justify-content: flex-end;
}

.mb_0 {
  margin-bottom: 0px !important;
}

.p_20 {
  padding: 20px;
}

.px_16 {
  padding: 0 16px;
}

.pb_15 {
  padding-bottom: 15px;
}

.pb_28 {
  padding-bottom: 28px;
}

.pt_28 {
  padding-top: 28px;
}

.clsToggleMarginMins {
  margin: 0 -16px;
}

.pb_56 {
  padding-bottom: 56px;
}

.clsBorder {
  border-top: solid 1px #DFE1E4;
}

.clsBorderTopNoneLst {
  div {
    &:last-child {
      .clsBorderTop {
        border: 0;
        padding: 0;
      }
    }
  }
}

.text_right {
  text-align: right;
}

.text_center {
  text-align: center;
}

.posUnset {
  position: unset !important;
}

.dropdownContainer {
  position: relative;
  display: inline-block;
}

.dropdownContainers {
  display: inline-block;
}

.clsSelectedTagsAbsolute {
  position: absolute;
  top: 0;
  z-index: 1;
  padding: 6px 16px;
}

.clsSelectedTagsRelative {
  position: relative;
  background-color: #fff;
  display: grid;
  top: 0;
  z-index: 1;
  grid-template-columns: min-content min-content min-content min-content;
  padding: 6px 16px;
  row-gap: 5px;
  border-width: 0px 1px;
  border-style: solid;
  border-color: #128292;
}

.clsHideOnTag {
  display: none;
}

.clsShowOnTag {
  display: block;
}

.clsTagListClearBtn {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 5px;
}

.clsNewTagListClearBtn {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 10px;
}

.clsSelectedTag {
  font-size: 12px;
  font-weight: 600;
  max-height: 24px;
  color: #66707d;
  padding: 2px 2px 2px 10px;
  border: solid 1px #dfe1e4;
  background-color: #fff;
  border-radius: 999px;
  display: inline-flex;
  align-items: center;
  margin-left: 0;
  // min-width: 220px;
  justify-content: space-between;
  margin-right: 6px;
  white-space: nowrap;

  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    border-color: #DFE1E4;
  }

  &:focus {
    border-color: #BCC1C7;
  }

  .clsListClearBtn {
    display: block;
    width: 20px;
    height: 20px;
    z-index: 2;
    padding: 0px;
    border-radius: 999px;
    margin-left: 10px;

    .clsListClearIcon {
      width: 100%;
      height: 100%;

      svg {
        width: 10px;
        height: 10px;
      }
    }

    &:hover {
      background-color: #99A0AA !important;

      .clsListClearIcon {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }

    &:focus {
      background-color: #66707D !important;

      .clsListClearIcon {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}

.addbgColor {
  background-color: #F3F5F8;
}

.arrowSection {
  display: flex;
  align-items: center;
}

.fullWidthBtn {
  min-width: 100%;
  display: flex;
  justify-content: center;
  border-top: solid 1px #DFE1E4;
}

.fixedShowAll {
  position: fixed;
  bottom: 5px;
  background-color: #fff;
  min-width: 30.3%;
  display: flex;
  align-items: center;
  bottom: 0;
}

.btnShowAllFilters {
  margin: 5px;
  color: #128292;
  background-color: #fff;

  &:hover {
    background-color: #dfe1e4;
    border-radius: 999px;
    color: #128292 !important;
  }

  &:active {
    background-color: #bcc1c7;
    border-color: #bcc1c7;
    border-radius: 999px;
    color: #128292 !important;
  }

  &:focus {
    color: #128292 !important;
  }
}

.innerListItem {
  display: flex;
  padding: 0px 27px;
  // border-radius: 8px;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 100%;
  justify-content: space-between;
  // cursor: pointer;
  // margin-bottom: 8px;
}

.innerListItemActive {
  display: flex;
  // padding: 0px 12px;
  // border-radius: 8px;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 100%;
  justify-content: space-between;
  // cursor: pointer;
  // margin-bottom: 8px;
}

.searchInput {
  position: relative;

  .iconSearch {
    position: absolute;
    top: 3px;
    right: 8px;
    z-index: 3;
    display: flex;
    align-items: center;
    border-radius: 999px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: #E9EBEE;
    }

    &::before {
      content: "";
      width: 1px;
      height: 24px;
      background-color: rgba(96, 106, 117, 0.6);
      left: -10px;
      position: absolute;
    }

    svg {
      width: 20px;
      height: 21px;
      fill: $color-orange;
    }

    .searchText {
      font-size: 14px;
      font-weight: 700;
      color: $color-orange;
      margin-left: 5px;
      font-family: $font-inter;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .search {
    height: 36px;
    min-width: 463px;
    width: 100%;
    border-radius: 999px;
    border: 1px solid rgba(96, 106, 117, 0.6);
    background: $color-white;
    // box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
    padding: 0;
    transition: all $transition-delay;
    color: $color-grey;
    font-size: 14px;
    z-index: 2;
    position: relative;
    overflow: hidden;

    input {
      font-family: $font-inter;
    }

    &:focus {
      background: $color-white;

      @include primary-color-box-shadow-inset();
    }

    :global {
      $Select-left-padding: 32px;

      .Select-control {
        border: 0;
        height: auto;
        color: $color-grey-1;
        padding-left: $Select-left-padding;

        .Select-value {
          left: $Select-left-padding !important;
        }

        .Select-input>input {
          width: auto !important;
          max-width: 230px;
        }
      }

      .Select-wrapper,
      .Select-placeholder {
        left: $Select-left-padding;
      }

      .Select-menu-outer {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid $color-blue-dark;
      }

      .Select-noresults {
        display: none;
      }

      .Select-arrow-zone {
        width: 5px;
      }
    }
  }
}

.fixedHoverSearchBtnDiv {
  position: fixed;
  z-index: 999;
  left: 55%;
  top: 100px;

  .searchIcon {
    margin-right: 5px;
  }
}



.dropdownToggleBtn {
  height: 36px;
  min-width: 100px;
  border: 1px solid #128292;
  border-radius: 999px;
  background: #128292;
  padding: 6px 20px;
  transition: all 150ms;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  position: relative;
  font-weight: 600;

  &.dropdownSaveSerchBtn {
    min-width: 175px;
  }

  &:focus {
    background: #0a464f;
  }

  &:hover {
    background-color: #0e6673;
  }

  @include text-overflow;
}

.dropdown {
  $sizeArrow: 5px;
  // top: calc(100% + 4px);
  // top: 50px;
  top: 0px;
  position: fixed;
  // margin-top: 5px;
  margin-top: 0px;
  // right: 0;
  // left: 0;
  // width: 600px;
  // left: 50%;
  transform: translateX(-50%);
  width: 95%;
  background: $color-white;
  box-shadow: 0 5px 10px 0px rgba(75, 75, 75, 0.5);
  border-radius: 12px;
  z-index: 4;
  // max-width: 1230px;
  width: 1278px;

  @include primary-border-color();
}

.dropdownSavedSearches {
  position: absolute;
  width: 100%;
  top: calc(100% + 4px);
  background: $color-white;
  border-radius: 5px;
  z-index: 99;
  border: solid 1px #128292;
  list-style-type: none;
  padding: 2px;
  margin: 0;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);

  @include primary-border-color();
}

.dropdownSavedSearchesOptions {
  max-height: 200px;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
}

.dropdownSavedSearches li {
  padding: 10px;
  cursor: pointer;
}

.dropdownSavedSearches li:hover {
  background-color: #f0f0f0;
}

.dropdownCaretIcon {
  width: 12px;
  height: 9px;
  position: absolute;
  top: 14px;
  right: 15px;
  fill: $color-white;
}

.filteredSubTitle {
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;
  display: inline-flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #53A6B2;
}

.dropdownCaretIconNew {
  width: 15px;
  height: 15px;
  position: relative;
  fill: #128292;
}

.dropdownHeader {
  padding: 16px 16px 8px;
  // border-bottom: 1px solid #ccc;
  background-color: #f3f5f8;

  .clsTopHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .row {
      $offset: 6px;

      display: flex;
      flex-wrap: wrap;
      // margin-right: -$offset;
      // margin-left: -$offset;

      .col {
        padding-left: $offset;
        padding-right: $offset;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .clsSearchBox {
      position: relative;

      .clsSearchInput {
        height: 36px;
        min-width: 460px;
        width: 100%;
        border-radius: 999px;
        border: 1px solid #dfe1e4;
        background: #fff;
        padding: 5px 16px;
        transition: all 150ms;
        // color: #3c4654;
        // font-size: 14px;
        z-index: 1;
        position: relative;
        overflow: hidden;

        &::placeholder {
          color: #99a0aa;
        }

        &:focus {
          border: 1px solid #128292;
          border-radius: 10px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }

      .clsBtnClear {
        display: block;
        width: 26px;
        height: 26px;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 30;
        padding: 5px;
        border-radius: 999px;

        .clsIconClear {
          width: 12px;
          height: 12px;

          .clsIconColor {
            fill: #99a0aa;
            stroke: #99a0aa;
          }
        }

        &:hover {
          background-color: #99a0aa;

          .clsIconClear {
            .clsIconColor {
              fill: #fff;
              stroke: #fff;
            }
          }
        }

        &:focus,
        &.active {
          background-color: #66707d;

          .clsIconClear {
            .clsIconColor {
              fill: #fff;
              stroke: #fff;
            }
          }
        }
      }

      .clsAutoSearchList {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        border: 1px solid #128292;
        border-top: none;
        border-radius: 0 0 12px 12px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 0;
        margin: 0;
        max-height: 150px;
        overflow-y: auto;
        /* Enable scrolling */
        z-index: 999;
        // border: solid 1px #128292;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        // .clsAutoSearchLoading {
        //   margin: 0;
        //   padding: 0;
        //   padding-right: 6px;
        //   max-height: 150px;
        //   overflow-y: auto;
        //   list-style: none;
        // }

        // .clsAutoSearchResult {
        //   margin: 0;
        //   padding: 0;
        //   padding-right: 6px;
        //   max-height: 150px;
        //   overflow-y: auto;
        //   list-style: none;
        // }
      }

      .clsAutoSearchOption {
        padding: 8px 16px;
        font-size: 16px;
        color: #3C4654;
        font-weight: 600 !important;
        cursor: pointer;
        margin: 0 5px;

        &:hover {
          background-color: #f0f1f3;
        }
      }
    }

    .clsClearFilterBtn {
      color: #3c4654;
      border: 1px solid #dfe1e4;
      border-radius: 999px;
      background-color: #fff;
      height: 36px;

      &:hover {
        background-color: #dfe1e4;
      }

      &:focus {
        background-color: #bcc1c7;
        border-color: #bcc1c7;
      }
    }

    .clsSaveBtn {
      color: #fff;
      border-radius: 999px;
      background-color: #128292;

      &:hover {
        background-color: #0e6673;
      }

      &:focus {
        background-color: #0a464f;
      }

      &.active {
        background-color: #128292;
      }
    }

    .clsPropertiBtn {
      background-color: #f16722;
      color: #fff;
      font-size: 14px;
      border-radius: 999px;

      &:hover {
        background-color: #d34f0d;
      }

      &:focus {
        background-color: #ab400b;
      }
    }

    .caption {
      font-size: 16px;
      line-height: 35px;
      color: $color-blue-dark;
    }

    // todo it's modal duplicate
    button.btnClose {
      $size: 21px;

      position: absolute;
      right: 20px;
      top: 12px;
      width: $size;
      height: $size;
      padding: 0;

      &:hover {
        opacity: 0.8;
      }

      .iconClose {
        width: $size;
        height: $size;
      }
    }
  }

  .clsBotHeader {
    display: flex;
    align-items: center;

    .clsShowAllBtn {
      font-size: 14px;
      font-weight: 600;
      color: #66707d;
      background-color: transparent;
      border-radius: 999px;
      margin-left: 8px;
      margin-bottom: 10px;

      &:hover {
        background-color: #DFE1E4;
      }

      &:focus {
        background-color: #BCC1C7;
      }

      &.active {
        background-color: #128292;
        color: #FFFFFF;
      }
    }

    .clsShowAllBtnDiv {
      flex: 1 1 100px;
    }

    .clsFilterScroll {
      flex: 1 1 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &::after,
      &::before {
        content: "";
        background: linear-gradient(90deg, rgb(243, 245, 248) 20%, rgb(255 255 255 / 0%) 100%);
        width: 10%;
        height: 36px;
        display: none;
        position: absolute;
        border-radius: 999px;
        left: 0;
        top: -3px;
      }

      &::before {
        content: "";
        background: linear-gradient(270deg, rgb(243, 245, 248) 20%, rgb(255 255 255 / 0%) 100%);
        left: auto;
        right: 0;
      }

      .clsFilterList {
        padding: 0;
        padding-bottom: 7px;
        margin: 0;
        list-style: none;
        overflow-x: auto;
        // max-width: 980px;
        width: calc(100vw - 400px);
        // width: 100%;
        display: flex;
        flex-wrap: nowrap;
        min-height: 40px;
        flex: 1 1 100%;

        li {
          font-size: 14px;
          font-weight: 500;
          color: #66707d;
          padding: 5px 16px;
          padding-right: 5px;
          border: solid 1px #dfe1e4;
          background-color: #fff;
          border-radius: 999px;
          display: inline-flex;
          align-items: center;
          // min-width: 220px;
          justify-content: space-between;
          margin-right: 6px;
          white-space: nowrap;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            border-color: #DFE1E4;
          }

          &:focus {
            border-color: #BCC1C7;
          }

          .clsListClearBtn {
            display: block;
            width: 20px;
            height: 20px;
            z-index: 2;
            padding: 0px;
            border-radius: 999px;
            margin-left: 10px;

            .clsListClearIcon {
              width: 100%;
              height: 100%;

              svg {
                width: 10px;
                height: 10px;
              }
            }

            &:hover {
              background-color: #99A0AA;

              .clsListClearIcon {
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
            }

            &:focus {
              background-color: #66707D;

              .clsListClearIcon {
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
            }
          }
        }
      }

      .clsFilterLftArrow {
        width: 36px;
        height: 36px;
        border-radius: 999px;
        background-color: #128292;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        cursor: pointer;
        padding: 10px;
        flex: 1 0 auto;

        &.clsFilterRitArrow {
          // right: 0;
          // left: auto;
          transform: rotate(180deg);
          box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.15);
        }


        &:hover {
          background-color: #0E6673;
        }

        &:focus {
          background-color: #0A464F;
        }

        .clsFilterArrow {
          width: 18px;
          height: 16px;
        }
      }
    }
  }

  .clsCloseBtnPadding {
    padding: 0 !important;
    width: 32px;
    height: 32px;
    border: 0;
    border-radius: 999px;
    span {
      display: inline-flex;
    }

    .clsListClearIcon {
      div {
        display: inline-flex;
      }

      width: 32px;
      height: 32px;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      padding: 6px 2px !important;
    }

    &:hover {
      background-color: #DFE1E4;
    }

    &:focus {
      background-color: #BCC1C7;
    }
  }
}

.searchRowClose {
  height: 36px;
}

.arrowBtn {
  width: 30px;
  height: 30px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  margin-right: 10px;
  padding: 6px 2px !important;

  &.closeBtn {
    margin-right: 0;
  }

  &:hover {
    background-color: #0E6673;

    #idAddBtn {
      filter: brightness(0) invert(1);
    }
  }

  &:focus {
    background-color: #0A464F;

    #idAddBtn {
      filter: brightness(0) invert(1);
    }
  }
}

// .topPadding {
//   padding-top: 55px;
// }

.clsSearchInputDiv {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.clsRemoveBottomBoder {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.clsSearchInputFocus {
  background-color: #0A464F;
  // height: 92%;
  width: 280px;
  height: 36px;
  border-radius: 12px;
}

.clsSearchInputInnerDiv {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;

}

.clsSearchInputOuterDiv {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.sidePadding_16 {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  // min-width: 70%; 
}

.clsNoneBg {
  background: none !important;
}

.highlightOrange {
  background-color: #F16722 !important;
}

.activeSuggestion {
  background-color: #0E6673;
  border-radius: 8px;
}

.clsFilteredLeadLabel {
  color: #fff;
  // font-size: 16px;
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
  display: inline-flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #53A6B2;
}

.tagCount {
  color: #B8DFE6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.dropdownTagHeader {
  padding: 16px 16px 0px;
  // width: 450px;
  // min-height: 36px;
  // position: fixed;
  // left: 50%;
  // z-index: 7;
  // border-bottom: 1px solid #ccc;
  // background-color: #f3f5f8;
  display: flex;
  justify-content: end;

  .clsTopHeader {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 16px;

    .row {
      $offset: 6px;

      display: flex;
      flex-wrap: wrap;
      margin-right: -$offset;
      margin-left: -$offset;

      .col {
        padding-left: $offset;
        padding-right: $offset;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .clsSearchBox {
      position: relative;

      .clsSearchInput {
        width: 430px;
        height: 44px;
        // width: 100%;  
        border-radius: 16px;
        background: #128292;
        padding: 4px 16px 4px 4px;
        transition: all 300ms;
        color: #3c4654;
        font-size: 14px;
        z-index: 1;
        position: relative;
        overflow: hidden;
        // box-shadow: 0px 6px 12px 2px #0000000F; 
        box-shadow: 0px 1px 5px 3px #00000021;


        &::placeholder {
          color: #E2F7FA;
        }

        &:focus {
          border: 1px solid #128292;
          border-radius: 10px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }

      .clsSearchInputField {
        height: 52px;
        // min-width: 460px;
        width: 100%;
        border: none;
        background: #128292;
        padding: 8px 16px 8px 8px;
        color: #E2F7FA;
        font-size: 16px;
        z-index: 1;
        position: relative;
        overflow: hidden;

        &::placeholder {
          color: #E2F7FA;
        }

        // &:focus{
        //   border: 1px solid #128292;
        //   background-color: #94C9D1;
        //   border-radius: 10px;
        //   height: 80%;
        //   border-bottom-left-radius: 0px;
        //   border-bottom-right-radius: 0px;
        // }
      }

      .clsBtnClear {
        display: block;
        width: 26px;
        height: 26px;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 30;
        padding: 5px;
        border-radius: 999px;

        .clsIconClear {
          width: 12px;
          height: 12px;

          .clsIconColor {
            fill: #99a0aa;
            stroke: #99a0aa;
          }
        }

        &:hover {
          background-color: #99a0aa;

          .clsIconClear {
            .clsIconColor {
              fill: #fff;
              stroke: #fff;
            }
          }
        }

        &:focus,
        &.active {
          background-color: #66707d;

          .clsIconClear {
            .clsIconColor {
              fill: #fff;
              stroke: #fff;
            }
          }
        }
      }

      .clsAutoSearchList {
        position: absolute;
        top: 88%;
        left: 4px;
        width: 280px;
        background-color: #0A464F;
        border-top: 1px solid #128292;
        border-radius: 0 0 12px 12px;
        box-shadow: 0px 2px 5px 0px #00000033;
        padding: 6px;
        margin: 0;
        max-height: 192px;
        overflow-y: auto;
        z-index: 999;

        &::-webkit-scrollbar {
          width: 12px;
          min-height: 3px;
        }

        &::-webkit-scrollbar-track {
          margin: 16px;
        }

        &::-webkit-scrollbar-thumb {
          border: 4px solid transparent;
          border-radius: 8px;
          background-clip: padding-box;
          background-color: #B8DFE6;
        }

        // .clsAutoSearchLoading {
        //   margin: 0;
        //   padding: 0;
        //   padding-right: 6px;
        //   max-height: 150px;
        //   overflow-y: auto;
        //   list-style: none;
        // }

        // .clsAutoSearchResult {
        //   margin: 0;
        //   padding: 0;
        //   padding-right: 6px;
        //   max-height: 150px;
        //   overflow-y: auto;
        //   list-style: none;
        // }
      }

      .clsAutoSearchOption {
        height: 36px;
        padding: 7px 16px; 
        font-size: 16px; 
        line-height: 19px;  
        color: #fff;
        font-weight: 600 !important;
        cursor: pointer; 
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin: 1px 0px;

        &:hover {
          background-color: #0E6673;
          border-radius: 8px; 
        }
      }
    }

    .clsClearFilterBtn {
      color: #3c4654;
      border: 1px solid #dfe1e4;
      border-radius: 999px;
      background-color: #fff;

      &:hover {
        background-color: #dfe1e4;
      }

      &:focus {
        background-color: #bcc1c7;
        border-color: #bcc1c7;
      }
    }

    .clsSaveBtn {
      color: #fff;
      border-radius: 999px;
      background-color: #128292;

      &:hover {
        background-color: #0e6673;
      }

      &:focus {
        background-color: #0a464f;
      }

      &.active {
        background-color: #128292;
      }
    }

    .clsPropertiBtn {
      background-color: #f16722;
      color: #fff;
      font-size: 14px;
      border-radius: 999px;

      &:hover {
        background-color: #d34f0d;
      }

      &:focus {
        background-color: #ab400b;
      }
    }

    .caption {
      font-size: 16px;
      line-height: 35px;
      color: $color-blue-dark;
    }

    // todo it's modal duplicate
    button.btnClose {
      $size: 21px;

      position: absolute;
      right: 20px;
      top: 12px;
      width: $size;
      height: $size;
      padding: 0;

      &:hover {
        opacity: 0.8;
      }

      .iconClose {
        width: $size;
        height: $size;
      }
    }
  }

  .clsBotHeader {
    display: flex;
    align-items: center;

    .clsShowAllBtn {
      font-size: 14px;
      font-weight: 600;
      color: #66707d;
      background-color: transparent;
      border-radius: 999px;
      margin-left: 8px;
      margin-bottom: 10px;

      &:hover {
        background-color: #DFE1E4;
      }

      &:focus {
        background-color: #BCC1C7;
      }

      &.active {
        background-color: #128292;
        color: #FFFFFF;
      }
    }

    .clsShowAllBtnDiv {
      flex: 1 1 100px;
    }

    .clsFilterScroll {
      flex: 1 1 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &::after,
      &::before {
        content: "";
        background: linear-gradient(90deg, rgb(243, 245, 248) 20%, rgb(255 255 255 / 0%) 100%);
        width: 10%;
        height: 36px;
        display: none;
        position: absolute;
        border-radius: 999px;
        left: 0;
        top: -3px;
      }

      &::before {
        content: "";
        background: linear-gradient(270deg, rgb(243, 245, 248) 20%, rgb(255 255 255 / 0%) 100%);
        left: auto;
        right: 0;
      }

      .clsFilterList {
        padding: 0;
        padding-bottom: 7px;
        margin: 0;
        list-style: none;
        overflow-x: auto;
        // max-width: 980px;
        width: calc(100vw - 400px);
        // width: 100%;
        display: flex;
        flex-wrap: nowrap;
        min-height: 40px;
        flex: 1 1 100%;

        li {
          font-size: 14px;
          font-weight: 500;
          color: #66707d;
          padding: 5px 16px;
          padding-right: 5px;
          border: solid 1px #dfe1e4;
          background-color: #fff;
          border-radius: 999px;
          display: inline-flex;
          align-items: center;
          // min-width: 220px;
          justify-content: space-between;
          margin-right: 6px;
          white-space: nowrap;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            border-color: #DFE1E4;
          }

          &:focus {
            border-color: #BCC1C7;
          }

          .clsListClearBtn {
            display: block;
            width: 20px;
            height: 20px;
            z-index: 2;
            padding: 0px;
            border-radius: 999px;
            margin-left: 10px;

            .clsListClearIcon {
              width: 100%;
              height: 100%;

              svg {
                width: 10px;
                height: 10px;
              }
            }

            &:hover {
              background-color: #99A0AA;

              .clsListClearIcon {
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
            }

            &:focus {
              background-color: #66707D;

              .clsListClearIcon {
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
            }
          }
        }
      }

      .clsFilterLftArrow {
        width: 36px;
        height: 36px;
        border-radius: 999px;
        background-color: #128292;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        cursor: pointer;
        padding: 10px;
        flex: 1 0 auto;

        &.clsFilterRitArrow {
          // right: 0;
          // left: auto;
          transform: rotate(180deg);
          box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.15);
        }


        &:hover {
          background-color: #0E6673;
        }

        &:focus {
          background-color: #0A464F;
        }

        .clsFilterArrow {
          width: 18px;
          height: 16px;
        }
      }
    }
  }
}

.dropdownTagHeaderRight {
  // width: 150px;
  // min-height: 36px;
  // position: fixed;
  // left: 85%;
  // z-index: 7;
  // border-bottom: 1px solid #ccc;
  // background-color: #f3f5f8;

  display: flex;
  justify-content: end;
  padding: 16px 16px 0;

  .clsTopHeader {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 16px;

    .row {
      $offset: 6px;

      display: flex;
      flex-wrap: wrap;
      margin-right: -$offset;
      margin-left: -$offset;

      .col {
        padding-left: $offset;
        padding-right: $offset;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .sidePadding_16 {
      padding: 0;
      display: flex;
      align-items: center;
      min-width: 60%;
      height: auto;
    }

    .clsSearchBox {
      position: relative;
      width: 148px;
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px 15px;
      border-radius: 999px;
      background: #128292;
      transition: all 300ms;
      box-shadow: 0px 1px 5px 3px #00000021;


      .clsSearchInput {
        // height: 52px;
        // min-width: 460px;
        // width: 100%; 
        // color: #3c4654;
        // font-size: 14px;
        z-index: 1;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &::placeholder {
          color: #E2F7FA;
        }

        &:focus {
          border: 1px solid #128292;
          border-radius: 10px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }

      .clsSearchInputField {
        // height: 52px;
        // min-width: 460px;
        // width: 100%;
        //styleName: textfield/placeholder text;  
        border: none;
        background-color: transparent;
        width: 100%;
        padding: 0px 0px 0px 6px;
        color: #E2F7FA;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        z-index: 1;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &::placeholder {
          color: #E2F7FA;
        }

        // &:focus{
        //   border: 1px solid #128292;
        //   background-color: #94C9D1;
        //   border-radius: 10px;
        //   height: 80%;
        //   border-bottom-left-radius: 0px;
        //   border-bottom-right-radius: 0px;
        // }
      }

      .clsBtnClear {
        display: block;
        width: 26px;
        height: 26px;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 30;
        padding: 5px;
        border-radius: 999px;

        .clsIconClear {
          width: 12px;
          height: 12px;

          .clsIconColor {
            fill: #99a0aa;
            stroke: #99a0aa;
          }
        }

        &:hover {
          background-color: #99a0aa;

          .clsIconClear {
            .clsIconColor {
              fill: #fff;
              stroke: #fff;
            }
          }
        }

        &:focus,
        &.active {
          background-color: #66707d;

          .clsIconClear {
            .clsIconColor {
              fill: #fff;
              stroke: #fff;
            }
          }
        }
      }

      .clsAutoSearchList {
        position: absolute;
        top: 85%;
        left: 4%;
        width: 55%;
        background-color: #0A464F;
        // border: 1px solid #128292;
        border-top: none;
        border-radius: 0 0 12px 12px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 6px;
        margin: 0;
        max-height: 150px;
        overflow-y: auto;
        z-index: 999;

        &::-webkit-scrollbar {
          width: 12px;
          min-height: 3px;
        }

        &::-webkit-scrollbar-track {
          margin: 16px;
        }

        &::-webkit-scrollbar-thumb {
          border: 4px solid transparent;
          border-radius: 8px;
          background-clip: padding-box;
          background-color: #B8DFE6;

        }



        // .clsAutoSearchLoading {
        //   margin: 0;
        //   padding: 0;
        //   padding-right: 6px;
        //   max-height: 150px;
        //   overflow-y: auto;
        //   list-style: none;
        // }

        // .clsAutoSearchResult {
        //   margin: 0;
        //   padding: 0;
        //   padding-right: 6px;
        //   max-height: 150px;
        //   overflow-y: auto;
        //   list-style: none;
        // }
      }

      .clsAutoSearchOption {
        padding: 8px 16px;
        font-size: 16px;
        color: #fff;
        font-weight: 600 !important;
        cursor: pointer;
        // margin: 0 2px;
        display: flex;
        justify-content: space-between;

        &:hover {
          background-color: #0E6673;
          border-radius: 8px;
          margin: 1px 0px;
        }
      }
    }

    .clsClearFilterBtn {
      color: #3c4654;
      border: 1px solid #dfe1e4;
      border-radius: 999px;
      background-color: #fff;

      &:hover {
        background-color: #dfe1e4;
      }

      &:focus {
        background-color: #bcc1c7;
        border-color: #bcc1c7;
      }
    }

    .clsSaveBtn {
      color: #fff;
      border-radius: 999px;
      background-color: #128292;

      &:hover {
        background-color: #0e6673;
      }

      &:focus {
        background-color: #0a464f;
      }

      &.active {
        background-color: #128292;
      }
    }

    .clsPropertiBtn {
      background-color: #f16722;
      color: #fff;
      font-size: 14px;
      border-radius: 999px;

      &:hover {
        background-color: #d34f0d;
      }

      &:focus {
        background-color: #ab400b;
      }
    }

    .caption {
      font-size: 16px;
      line-height: 35px;
      color: $color-blue-dark;
    }

    // todo it's modal duplicate
    button.btnClose {
      $size: 21px;

      position: absolute;
      right: 20px;
      top: 12px;
      width: $size;
      height: $size;
      padding: 0;

      &:hover {
        opacity: 0.8;
      }

      .iconClose {
        width: $size;
        height: $size;
      }
    }
  }

  .clsSearchInputDiv {
    display: none
  }

  .clsBotHeader {
    display: flex;
    align-items: center;

    .clsShowAllBtn {
      font-size: 14px;
      font-weight: 600;
      color: #66707d;
      background-color: transparent;
      border-radius: 999px;
      margin-left: 8px;
      margin-bottom: 10px;

      &:hover {
        background-color: #DFE1E4;
      }

      &:focus {
        background-color: #BCC1C7;
      }

      &.active {
        background-color: #128292;
        color: #FFFFFF;
      }
    }

    .clsShowAllBtnDiv {
      flex: 1 1 100px;
    }

    .clsFilterScroll {
      flex: 1 1 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &::after,
      &::before {
        content: "";
        background: linear-gradient(90deg, rgb(243, 245, 248) 20%, rgb(255 255 255 / 0%) 100%);
        width: 10%;
        height: 36px;
        display: none;
        position: absolute;
        border-radius: 999px;
        left: 0;
        top: -3px;
      }

      &::before {
        content: "";
        background: linear-gradient(270deg, rgb(243, 245, 248) 20%, rgb(255 255 255 / 0%) 100%);
        left: auto;
        right: 0;
      }

      .clsFilterList {
        padding: 0;
        padding-bottom: 7px;
        margin: 0;
        list-style: none;
        overflow-x: auto;
        // max-width: 980px;
        width: calc(100vw - 400px);
        // width: 100%;
        display: flex;
        flex-wrap: nowrap;
        min-height: 40px;
        flex: 1 1 100%;

        li {
          font-size: 14px;
          font-weight: 500;
          color: #66707d;
          padding: 5px 16px;
          padding-right: 5px;
          border: solid 1px #dfe1e4;
          background-color: #fff;
          border-radius: 999px;
          display: inline-flex;
          align-items: center;
          // min-width: 220px;
          justify-content: space-between;
          margin-right: 6px;
          white-space: nowrap;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            border-color: #DFE1E4;
          }

          &:focus {
            border-color: #BCC1C7;
          }

          .clsListClearBtn {
            display: block;
            width: 20px;
            height: 20px;
            z-index: 2;
            padding: 0px;
            border-radius: 999px;
            margin-left: 10px;

            .clsListClearIcon {
              width: 100%;
              height: 100%;

              svg {
                width: 10px;
                height: 10px;
              }
            }

            &:hover {
              background-color: #99A0AA;

              .clsListClearIcon {
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
            }

            &:focus {
              background-color: #66707D;

              .clsListClearIcon {
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
            }
          }
        }
      }

      .clsFilterLftArrow {
        width: 36px;
        height: 36px;
        border-radius: 999px;
        background-color: #128292;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        cursor: pointer;
        padding: 10px;
        flex: 1 0 auto;

        &.clsFilterRitArrow {
          // right: 0;
          // left: auto;
          transform: rotate(180deg);
          box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.15);
        }


        &:hover {
          background-color: #0E6673;
        }

        &:focus {
          background-color: #0A464F;
        }

        .clsFilterArrow {
          width: 18px;
          height: 16px;
        }
      }
    }
  }
}

.redText {
  color: #128292 !important;
}

.back2SearchBtn {
  background-color: #f16722;
  color: #fff;
  font-size: 14px;
  border-radius: 999px;

  &:hover {
    background-color: #d34f0d;
    color: #fff !important;
  }

  &:focus {
    background-color: #ab400b;
    color: #fff !important;
  }
}

.dropdownBody {
  padding-top: 16px;
  background-color: #fff;
}

.dropdownFooter {
  padding: 8px;
  text-align: right;
}

.dropdownCloseBtn {
  padding: 8px 16px;
  background-color: #ccc;
  color: white;
  border: none;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity (0.5) to control darkness */
  z-index: 3;
  /* Ensure the overlay is above other content */
}

.dropdownCloseBtn:hover {
  background-color: #bbb;
}

.dropdown1 {
  $sizeArrow: 5px;
  top: calc(100% + 4px);
  position: absolute;
  margin-top: 5px;
  right: 0;
  width: 445px;
  border: 2px solid transparent;
  background: $color-white;
  box-shadow: $dropdown-shadow;
  border-radius: 2px;
  z-index: 1;

  @include primary-border-color();

  &:before,
  &:after {
    content: "";
    position: absolute;
    background: $color-dirty-white;
  }

  &:before {
    right: 40px;
    top: -3px;
    width: $sizeArrow;
    height: $sizeArrow;
    transform: rotate(45deg);

    @include primary-color-box-shadow();
  }

  &:after {
    height: $sizeArrow + 2;
    top: 0;
    width: 15px;
    right: 35px;
  }
}

.m_0 {
  margin: 0 !important;
}

.pt_24 {
  padding-top: 24px;
}

.dropdownBody {
  padding-top: 16px;
  background-color: #fff;

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;

    .col {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .clsLeftBody {
    padding: 0 8px;
    height: calc(100vh - 210px);
    overflow-y: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    .clsMenuList {
      padding: 0;
      margin: 0;

      li {
        // display: flex;
        padding: 12px 12px;
        border-radius: 12px;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
          // margin-bottom: 50px;
        }

        // &:hover {
        //   background-color: #f3f5f8;
        // }

        &.active {
          background-color: #dfe1e4;
        }

        .clsContent {
          padding-right: 12px;

          h4 {
            color: #3c4654;
            font-size: 18px;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 4px;
          }

          p {
            color: #66707d;
            font-size: 14px;
            line-height: 15px;
            margin-bottom: 0;
          }
        }

        .clsCounter {
          background-color: #128292;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          border-radius: 999px;
          margin-bottom: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          min-width: 24px;
          min-height: 24px;
        }
      }

      .lastItem {
        margin-bottom: 60px !important;
      }
    }
  }

  .clsRightBody {
    border: solid 1px #dfe1e4;
    border-radius: 8px;
    margin-bottom: 8px;

    .clsRightBodyScroll {
      // margin: 6px;
      padding: 6px;
      padding-right: 0px;
      height: calc(100vh - 230px);
      overflow-y: auto;

      // &::-webkit-scrollbar {
      //   width: 6px;
      //   height: 6px;
      // }

      &::-webkit-scrollbar {
        width: 10px;
        min-height: 3px;
      }

      &::-webkit-scrollbar-track {
        margin: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 8px;
        background-clip: padding-box;
        background-color: #DFE1E4;
      }
      
      .clsContentBody {
        padding: 18px;

        .flexRow {
          display: flex;
          align-items: center;
        }

        .inteliiIcon {
          display: inline-block;
          height: 15px;
          width: 16px;
          padding-right: 4px;
        }

        .propConditionInfoText {
          color: #66707D;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
        }

        .clsHeading {
          font-size: 34px;
          line-height: 100%;
          color: #3c4654;
          font-weight: 600;
          margin-top: 0;
          margin-bottom: 0px;
          letter-spacing: -0.72px;
        }

        .clsSubSecHeading {
          color: #3C4654;
          font-family: Inter;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          letter-spacing: -0.48px;
          margin-bottom: 28px;
        }

        .clsSubHeading {
          font-size: 16px;
          line-height: 16px;
          color: #3c4654;
          font-weight: 600;
          margin-top: 0px;
          margin-bottom: 8px;
        }

        .clsSecHeading {
          font-size: 24px;
          line-height: 24px;
          color: #3C4654;
          font-weight: 600;
          margin-top: 0;
          margin-bottom: 0px;
        }

        .row {
          $offset: 5px;

          display: flex;
          flex-wrap: wrap;
          margin-right: -$offset;
          margin-left: -$offset;

          .col {
            padding-left: $offset;
            padding-right: $offset;
            margin-bottom: 16px;
          }
        }

        .clsLeadList {
          display: flex;
          align-items: center;
          border: solid 1px #dfe1e4;
          border-radius: 12px;
          // padding: 16px 16px 16px 12px;
          padding: 7px 12px 7px 12px;
          height: 100%;

          &:hover {
            background-color: #DFE1E4;
            border-color: #DFE1E4;
          }

          &:focus,
          &:active {
            background-color: #BCC1C7;
            border-color: #BCC1C7;
          }

          &.active {
            background-color: #128292;
            border-color: #128292;

            .clsListIcon {
              .svgIcon {
                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
            }

            .clsLeadData {
              .clsLeadLabel {
                color: #fff;
              }

              .clsLeadValue {
                color: #fff;
              }
            }
          }

          .clsListIcon {
            // width: 40px;
            // height: 40px;
            width: 28px;
            height: 28px;
            flex-shrink: 0;
            margin-right: 8px;

            .svgIcon {
              width: 100%;
              height: 100%;

              svg {
                // width: 40px;
                // height: 40px;
                width: 28px;
                height: 28px;
              }
            }

            img {
              width: 100%;
            }
          }

          .clsLeadData {
            .clsLeadLabel {
              color: #3c4654;
              // font-size: 16px;
              font-size: 14px;
              line-height: 14px;
              font-weight: 600; 
              margin-bottom: 4px;
            }

            .clsLeadValue {
              color: #66707d;
              // font-size: 24px;
              font-size: 14px;
              line-height: 14px;
              font-weight: 500;
              text-align: left; 
              margin-bottom: 0px;
            }
          }
        }

        .clsBgGray {
          background: #F3F5F8;
          padding: 8px;
          border-radius: 30px;
        }

        .selctAllBtn {
          color: #66707D;
          font-size: 14px;
          font-weight: 500 !important;
          line-height: 14px;
          background-color: #fff;
          border: solid 1px #DFE1E4;
          border-radius: 999px;
          padding: 7px 12px !important;
          padding-left: 35px !important;
          position: relative;
          margin-left: 20px;

          &:hover {
            background-color: #DFE1E4;
            color: #66707d !important;

            &.unSelctCrle {
              border-color: #fff;
            }
          }

          &:focus,
          &:active,
          &.active {
            background-color: #BCC1C7;
            color: #66707d !important;

            &.unSelctCrle {
              border-color: #fff;
            }
          }

          &.checkSelctedBtn {
            color: #fff;
            background-color: #128292;

            .checkSelctCrle {
              background-color: #fff;
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }

            &:hover {
              background-color: #0E6673;
              color: #fff !important;

              .checkSelctCrle {
                .svgCheckIcon {
                  svg {
                    path {
                      fill: #0E6673;
                    }
                  }
                }
              }
            }

            &:focus,
            &:active,
            &.active {
              background-color: #0A464F;
              color: #fff !important;

              .checkSelctCrle {
                .svgCheckIcon {
                  svg {
                    path {
                      fill: #0A464F;
                    }
                  }
                }
              }
            }
          }

          .selctAllCrle {
            width: 20px;
            height: 20px;
            border-radius: 999px;
            display: inline-block;
            position: absolute;
            left: 6px;
            top: 50%;
            transform: translateY(-50%);

            &.minSelctCrle {
              background-color: #128292;

              &::after {
                content: "";
                width: 12px;
                height: 2px;
                border-radius: 999px;
                background-color: #fff;
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }

            &.unSelctCrle {
              background-color: #fff;
              border: solid 1px #DFE1E4;
            }
          }
        }

        .clsGroupBtn {
          padding-top: 12px;

          .clsBtnList {
            color: #66707D;
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
            background-color: #fff;
            border: solid 1px #DFE1E4;
            border-radius: 999px;
            margin-right: 8px;
            margin-bottom: 8px;
            margin-left: 0;

            &:last-child {
              margin-right: 0;
            }

            &.clsActive {
              background-color: #128292;
              color: #fff;
            }
          }

          .clsShowAll {
            color: #128292;
            background: transparent;
            border-radius: 999px;
            margin-left: 0;

            &:hover {
              background: #DFE1E4;
            }

            &:focus,
            &:active,
            &.active {
              background: #BCC1C7;
            }
          }
        }

        .clsFilterSelect {
          position: relative;

          .form_control {
            margin-right: 2px;
            border: solid 1px #DFE1E4;
            color: #3C4654;
            // color: #99A0AA;
            box-shadow: none;
            padding: 10px 15px;
            height: 44px;
            border-radius: 0;
            width: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            position: relative;


            &:focus {
              border-color: #128292;
            }

            &:first-child {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }

            &:last-child {
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              margin-right: 0;
            }
          }

          option:not(:checked) {
            color: #3C4654;
            border: solid 1px #128292;
            background: transparent;
          }

        }

        .clsFilterTab {
          display: block;

          .nav {
            border: solid 1px #DFE1E4;
            border-radius: 999px;
            background-color: #fff;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 3px;
            padding: 3px;

            li {
              flex: 1 1 auto;
              text-align: center;
              margin-right: 2px;

              &:last-child {
                margin-left: 0;
              }

              a {
                color: #66707D;
                font-size: 14px;
                border: 0 !important;
                border-radius: 999px;
                padding: 5px;
                cursor: pointer;
                outline: none !important;

                &:hover {
                  background-color: #DFE1E4;
                }

                &:focus {
                  background-color: #BCC1C7;
                }
              }

              &.active {
                a {
                  background-color: #128292;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dropdownFooter {
  padding: 0px;
  text-align: right;
}

.dropdownCloseBtn {
  padding: 8px 16px;
  background-color: #ccc;
  color: white;
  border: none;
  cursor: pointer;
}

.dropdownCloseBtn:hover {
  background-color: #bbb;
}

.dropdown1 {
  $sizeArrow: 5px;
  top: calc(100% + 4px);
  position: absolute;
  margin-top: 5px;
  right: 0;
  width: 445px;
  border: 2px solid transparent;
  background: $color-white;
  box-shadow: $dropdown-shadow;
  border-radius: 2px;
  z-index: 1;

  @include primary-border-color();

  &:before,
  &:after {
    content: "";
    position: absolute;
    background: $color-dirty-white;
  }

  &:before {
    right: 40px;
    top: -3px;
    width: $sizeArrow;
    height: $sizeArrow;
    transform: rotate(45deg);

    @include primary-color-box-shadow();
  }

  &:after {
    height: $sizeArrow + 2;
    top: 0;
    width: 15px;
    right: 35px;
  }
}

.pb_24 {
  padding-bottom: 24px;
}

.clsSaveSearchPopup {
  padding: 24px 16px 16px 16px;
  border: solid 2px #128292;
  box-shadow: 2px 6px 10px 0px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  width: 690px;
  margin: 0 auto;
  background-color: #F3F5F8;

  .clsSaveSearchPopupInnerScroll {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding-right: 16px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  }

  .clsSaveSearchPopupHeading {
    color: #3C4654;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
  }

  .clsLabel {
    color: #3C4654;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    margin-bottom: 10px;
    display: block;
    text-align: left;
  }

  .clsFormControl {
    background-color: #fff;
    display: block;
    width: 100%;
    border-radius: 12px;
    padding: 16px;
    min-height: 44px;
    border: solid 1px #DFE1E4;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #99A0AA;
    box-shadow: none;
    outline: none;

    &::placeholder {
      color: #99A0AA;
    }
  }

  .selctAllBtn {
    color: #66707D;
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 14px;
    background-color: #fff;
    border: solid 1px #DFE1E4;
    border-radius: 999px;
    padding: 7px 12px !important;
    padding-left: 35px !important;
    position: relative;

    &:hover {
      background-color: #DFE1E4;
      color: #66707d !important;

      &.unSelctCrle {
        border-color: #fff;
      }
    }

    &:focus,
    &:active,
    &.active {
      background-color: #BCC1C7;
      color: #66707d !important;

      &.unSelctCrle {
        border-color: #fff;
      }
    }

    &.checkSelctedBtn {
      color: #fff;
      background-color: #128292;

      .checkSelctCrle {
        background-color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background-color: #0E6673;
        color: #fff !important;

        .checkSelctCrle {
          .svgCheckIcon {
            svg {
              path {
                fill: #0E6673;
              }
            }
          }
        }
      }

      &:focus,
      &:active,
      &.active {
        background-color: #0A464F;
        color: #fff !important;

        .checkSelctCrle {
          .svgCheckIcon {
            svg {
              path {
                fill: #0A464F;
              }
            }
          }
        }
      }
    }

    .selctAllCrle {
      width: 20px;
      height: 20px;
      border-radius: 999px;
      display: inline-block;
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);

      &.minSelctCrle {
        background-color: #128292;

        &::after {
          content: "";
          width: 12px;
          height: 2px;
          border-radius: 999px;
          background-color: #fff;
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &.unSelctCrle {
        background-color: #fff;
        border: solid 1px #DFE1E4;
      }
    }
  }

  .clsPopupBtn {
    font-size: 18px;
    font-weight: 18px;
    border-radius: 999px;
    padding: 12px 25px !important;
    font-weight: 600 !important;

    &.clsCancelBtn {
      background-color: transparent;
      color: #128292;

      &:hover {
        background-color: #DFE1E4;
      }

      &:focus,
      &.active {
        background-color: #BCC1C7;
      }
    }

    &.clsSaveBtn {
      background-color: #F16722;
      color: #FFFFFF;

      &:hover {
        background-color: #D34F0D;
      }

      &:focus,
      &.active {
        background-color: #AB400B;
      }
    }
  }
}

.clsShowAllMenuPopup {
  // padding: 16px;
  // padding-right: 5px;
  // border: solid 2px #128292;
  // box-shadow: 2px 6px 10px 0px rgba(0, 0, 0, 0.16);
  // border-radius: 12px;
  // width: 500px;
  // margin: 0 auto;
  // background-color: #F3F5F8; 

  .clsInnerScroll {
    max-height: calc(100vh - 240px);
    overflow-y: auto;

    // padding-right: 16px;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  }

  .clsHeading {
    color: #3C4654;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 6px;
  }

  .clsCloseBtn {
    width: 30px;
    height: 30px;
    border-radius: 999px;
    outline: none !important;
    background: transparent;
    border: 0 !important;
    padding: 8px !important;
    margin-bottom: 0 !important;

    .svgCloseIcon {
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 12px;
    }

    &:hover {
      background-color: #DFE1E4;
    }

    &:focus {
      background-color: #BCC1C7;
    }
  }

  .clsModuleBox {
    background-color: #128292;
    border-radius: 6px;
    padding: 12px;
    display: flex;
    align-items: center;
    // margin-bottom: 16px;

    .clsModuleIcon {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      margin-right: 10px;

      .svgIcon {
        width: 100%;
        height: 100%;

        svg {
          width: 30px;
          height: 30px;

          path {
            fill: #fff;
          }
        }
      }
    }

    .clsModuleName {
      font-size: 16px;
      color: #fff;
      font-weight: 600;
      margin-bottom: 0;
      flex: 1;
    }

    .clsCloseBtn {

      &:hover {
        background-color: #53A6B2;
      }

      &:focus {
        background-color: #94C9D1;
      }
    }
  }

  .clsFilterModule {
    padding: 0;
    margin: 0;
    list-style: none;

    .clsFilterModuleList {
      // margin-bottom: 16px;
      padding: 12px 0px !important;

      &:last-child {
        margin-bottom: 0px;
      }

      .clsFilterModuleBox {
        padding: 0;
        margin: 0;
        list-style: none;

        .clsFilterModuleBoxList {
          background-color: #fff;
          border-radius: 6px;
          padding: 6px 12px 15px 12px;
          margin-bottom: 6px;
          position: relative;

          &:last-child {
            margin-bottom: 0;
          }

          .clsFilterModuleBoxHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 6px;

            h2 {
              font-size: 16px;
              line-height: 16px;
              font-weight: 600;
              color: #3C4654;
              margin: 0;
              max-width: 230px;
            }

            .clsBtnGrp {
              .clsEditBtn {
                color: #128292;
                font-size: 14px;
                font-weight: 500;
                background-color: transparent;
                padding: 6px 13px;
                border-radius: 999px;
                margin: 0 !important;

                &:hover {
                  background-color: #DFE1E4;
                }

                &:focus {
                  background-color: #BCC1C7;
                }
              }

              .clsCloseBtn {
                .svgCloseIcon {
                  svg {
                    path {
                      stroke: #128292;
                    }
                  }
                }
              }
            }
          }

          .clsFilterModulePara {
            margin-bottom: 0;
            color: #66707D;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            max-width: 340px;
          }

          .clsSavedBtn {
            background-color: #BCC1C7;
            color: #fff;
            font-weight: 500;
            font-size: 12px;
            border-radius: 0;
            border-top-left-radius: 8px;
            border-bottom-right-radius: 12px;
            padding: 1px 10px;
            position: absolute;
            right: 0;
            bottom: 0;
            margin: 0 !important;
          }
        }
      }

    }
  }
}

.clsToggleTab {
  border: solid 1px #DFE1E4;
  border-radius: 999px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 3px;

  li {
    flex: 1 1 auto;
    text-align: center;
    margin-right: 2px;

    &:last-child {
      margin-left: 0;
    }

    span {
      color: #66707D;
      font-weight: 600;
      font-size: 16px;
      border: 0 !important;
      border-radius: 999px;
      padding: 5px 22px;
      cursor: pointer;
      outline: none !important;
      display: inline-flex;
      height: 38px;
      align-items: center;
      justify-content: center;
      width: 100%;

      &:hover {
        background-color: #DFE1E4;
      }

      &:focus {
        background-color: #BCC1C7;
      }
    }

    &.active {
      span {
        background-color: #128292;
        color: #fff;
      }
    }
  }

  &.clsSmallToggle {
    li {
      span {
        height: 30px;
      }
    }
  }
}

.clsBorderTop {
  border-top: solid 1px #DFE1E4;
}

.headerBg {
  // background-color: #dfe1e4;
  margin-bottom: 28px;
  // border-radius: 8px;
  // padding: 12px 16px;
  padding-top: 14px;
  border-top: 1px solid #DFE1E4;
  // width: fit-content;
}

.leftAlign {
  left: 0;
  transform: none !important;
}

.clsSearchMenuPopup {
  padding: 16px;
  padding-right: 5px;
  border: solid 2px #128292;
  box-shadow: 2px 6px 10px 0px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  width: 600px;
  margin: 0 auto;
  background-color: #F3F5F8;
  position: absolute;
  // left: 50%;
  transform: translateX(-30%);
  z-index: 99;
  top: calc(100% + 4px);

  .clsInnerScroll {
    max-height: calc(100vh - 190px);
    overflow-y: auto;
    padding-right: 16px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  }

  .clsCardBody {
    padding: 0;
    margin: 0;
    list-style: none;

    .clsCardBodyList {
      background-color: #fff;
      padding: 12px;
      border-radius: 12px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background-color: #DFE1E4;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .clsAddBtn {
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 999px;
        margin-right: 16px;
        padding: 0;

        &:hover {
          background-color: #128292;

          #idAddBtn {
            filter: brightness(0) invert(1);
          }
        }

        &.focused {
          background-color: #0E6673;

          #idAddBtn {
            filter: brightness(0) invert(1);
          }
        }
      }

      .clsContBody {
        flex: 1;

        .clsHedTxt {
          color: #3C4654;
          font-size: 16px;
          line-height: 16px;
          font-weight: 600;
          margin-top: 0;
          margin-bottom: 8px;
        }

        .clsSubTxt {
          color: #66707D;
          font-family: 14px;
          line-height: 16px;
          font-weight: 500;
          margin: 0;
        }
      }

      .clsDateTxt {
        color: #66707D;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        line-height: 14px;
        margin-right: 10px;
      }

      .clsDeleteBtn {
        color: #128292;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        border-radius: 999px;
        padding: 7px 14px;

        &:hover {
          background-color: #128292;
          color: #fff;
        }

        &:focus {
          background-color: #0E6673;
          color: #fff;
        }
      }

      .clsRemoveDeleteBtn {
        width: 72px;
      }
    }
  }
}

.clsHomeScreenPopup {
  padding: 0;
  border: solid 2px #128292;
  box-shadow: 2px 6px 10px 0px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  width: auto;
  height: auto;
  margin: 0 auto;
  background-color: #F3F5F8;
  position: absolute;
  z-index: 999;
  left: 20px;

  .clsHeading {
    color: #3C4654;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .clsLabel {
    color: #3C4654;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    margin-bottom: 8px;
    display: block;
    text-align: left;
  }

  .clsNameTxt {
    color: #66707D;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
  }

  .clsPopupBtn {
    font-size: 18px;
    font-weight: 18px;
    border-radius: 999px;
    padding: 12px 25px !important;
    font-weight: 600 !important;

    &.clsCancelBtn {
      background-color: transparent;
      color: #128292;

      &:hover {
        background-color: #DFE1E4;
      }

      &:focus,
      &.active {
        background-color: #BCC1C7;
      }
    }

    &.clsSaveBtn {
      background-color: #F16722;
      color: #FFFFFF;

      &:hover {
        background-color: #D34F0D;
      }

      &:focus,
      &.active {
        background-color: #AB400B;
      }
    }
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
  /* Optional: Prevent interaction when hidden */
}

.filterExtraText {
  text-align: center;
  margin: 1rem 0;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 14px;
}

// responsive css start

@media (min-width: 768px) and (max-width: 1250px) {}

@media (min-width: 768px) and (max-width: 1150px) {
  .dropdownHeader {
    .clsTopHeader {
      .clsSearchBox {
        .clsSearchInput {
          min-width: 350px;
        }
      }
    }
  }
}

// responsive css end