:global {
  .fr-box {
    //height: 250px;
  }

  .fr-quick-insert { display: none; }

  .fr-box .fr-view {
    // CSS defaults must also be applied to viewer's text element, as well as Flying Saucer's config on server.
    font-family: AvenirNext;

    // Leave font size as the minimum supported, otherwise larger font sizes' line height would dictate wysiwyg's paragraph heights.
    font-size: 6px;
  }

  .fr-box .fr-wrapper {
    white-space: nowrap;
    overflow: auto;

    p,
    pre {
      margin: 0;
    }
  }

  .fr-box .fr-wrapper .fr-view {
    background: #d1d1d1;
    height: 100%;
  }

  .fr-box .fr-placeholder {
    font-size: 32px !important;
    line-height: 32px !important;
    z-index: 5;
    pointer-events: none;
  }
}
