@use "sass:map";
@import './variables';
@import './themes';

@mixin transition($attr) {
  transition: $attr $transition-delay $default-animation;
}

@mixin text-overflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::-ms-input-placeholder {
    color: $color;
  }
}

@mixin themify($property, $key, $themes, $before: '', $after: '') {
  /* stylelint-disable */
  @each $theme, $props in $themes {
    :global(.theme-#{$theme}) & {
      #{$property}: map-get($props, $key);
    }
  }
  /* stylelint-enable */
}

@mixin brand-themify($property, $key, $before: '', $after: '') {
  @include themify($property, $key, $brands, $before, $after);
}

@mixin primary-color() {
  @include brand-themify('color', 'primary-color');
}

@mixin primary-background-color() {
  @include brand-themify('background-color', 'primary-color');
}

@mixin primary-border-color() {
  @include brand-themify('border-color', 'primary-color');
}

@mixin primary-border-top-color() {
  @include brand-themify('border-top-color', 'primary-color');
}

@mixin primary-border-right-color() {
  @include brand-themify('border-right-color', 'primary-color');
}

@mixin primary-border-bottom-color() {
  @include brand-themify('border-bottom-color', 'primary-color');
}

@mixin primary-border-left-color() {
  @include brand-themify('border-left-color', 'primary-color');
}

@mixin primary-fill-color() {
  @include brand-themify('fill', 'primary-color');
}

@mixin primary-color-box-shadow($spread: '2px', $blur: '0') {
  @include brand-themify('box-shadow', 'primary-color', '0 0 #{$blur} #{$spread} ');
}

@mixin primary-color-box-shadow-inset() {
  @include brand-themify('box-shadow', 'primary-color', '0 0 0 2px ', ' inset');
}

@mixin primary-color-outline() {
  @include brand-themify('outline', 'primary-color', '1px solid ');
}

@mixin primary-color-outline-color() {
  @include brand-themify('outline-color', 'primary-color');
}

@mixin primary-color-dark() {
  @include brand-themify('color', 'primary-color-dark');
}

@mixin primary-background-color-dark() {
  @include brand-themify('background-color', 'primary-color-dark');
}

@mixin primary-border-color-dark() {
  @include brand-themify('border-color', 'primary-color-dark');
}

@mixin primary-fill-color-dark() {
  @include brand-themify('fill', 'primary-color-dark');
}

@mixin primary-background-color-light() {
  @include brand-themify('background-color', 'primary-color-light');
}


.clsSearchFilterModal{
  background-color: transparent !important;

  .modalContent{
    background: #F3F5F8 !important;
  }
}