@import '~app/mixins';

.notifications {
  display: flex;

  .section {
    margin-top: 20px;
    margin-bottom: 60px;

    .sectionHeader {
      font-weight: bold;
      padding-bottom: 8px;
      border-bottom: 2px solid #ddd;
    }

    .item {
      margin: 15px 0;
      display: flex;

      > div {
        &.options {
          width: 300px;
          display: flex;
          justify-content: flex-end;

          > div {
            width: 100px;
          }
        }

        &:first-child {
          width: 320px;
        }
      }
    }
  }
}
