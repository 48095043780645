@import "./../../../app/variables.scss";

.input {
  height: 26px;
  width: 100%;
  border: 1px solid $color-grey;
  color: #9d9d9d;
  border-radius: 4px;
  background: $color-white;
  padding: 6px;
  outline: 0;
  font-size: 12px;

  &::-ms-expand {
    display: none;
  }
}
