@use 'sass:math';

@import "~app/mixins";

.appHeader {
  width: 100%;
  border-bottom: 1px solid $color-border;
  border-left: 1px solid $color-border;
  background-color: $color-dirty-white;
  margin-left: auto;
  padding: 6px;
  position: relative;
  z-index: 2;

  .top,
  .bottom {
    display: flex;
    justify-content: space-between;
  }

  .top {
    align-items: center;

    .row {
      $offset: 6px;

      margin-right: -$offset;
      margin-left: -$offset;

      .col {
        padding-left: $offset;
        padding-right: $offset;
        float: left;
        .clearAllBtn {
          color: #66707D !important;
          font-family: $font-inter;
          border-color: #66707D;
          border-radius: 20px;
          &:hover{
            background-color: #DFE1E4;
          }
        }
      }
    }
  }

  .bottom {
    margin-top: 12px;
    display: block;
    .row {
      $offset: 4px;

      margin-right: -$offset;
      margin-left: -$offset;

      .col {
        padding-left: $offset;
        padding-right: $offset;
        width: 139px;
        float: left; 
        .restrictedContent {
          display: block !important;
        }
      }
    }

    .clsCardSection {
      display: flex;
      justify-content: space-between;
    }

    .restrictedContent {
      display: block !important;
    }
  }

  .savedSearch {
    .buttonSaveSearch {
      background-color: $color-orange !important;
      border-color: $color-orange !important;
      color: $color-white !important;
      &:hover {
        background-color: #D34F0D !important;
        border-color: #D34F0D !important;
      }
      &:focus {
        background-color: #ab400b;
      }
    }


    .buttonSave {
      color: #fff;
      border-radius: 999px;
      background-color: #128292;
      border-color: #128292;

      &:hover {
        background-color: #0e6673;
        border-color: #0e6673;
      }

      &:focus {
        background-color: #0a464f;
        border-color: #0a464f;
      }

      &.active {
        background-color: #128292;
        border-color: #128292;
      }
    }

  }



  .showAll {
    button {
      font-size: 14px;
      font-weight: 600;
      color: #66707d;
      background-color: transparent;
      border-radius: 999px;
      margin-left: 8px;
      margin-bottom: 10px;
      white-space: nowrap;
    
      &:hover {
        background-color: #DFE1E4;
        color: #66707d;
      }
    
      &:focus {
        background-color: #BCC1C7;
        color: #66707d;
      }
    
      &.active {
        background-color: #128292;
        color: #FFFFFF;
      }
    }
  }

}

.filter {
  height: 36px;
  min-width: 100px;
  border: 1px solid $color-teal;
  border-radius: 999px;
  background: $color-teal;
  padding: 6px 20px;
  transition: all $transition-delay;
  color: $color-white;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  &:focus {
    background: $color-white;

    @include primary-color-box-shadow-inset();
  }

  &:hover {
    background-color: #0E6673;
  }
  .value {
    min-width: 65px;
    max-width: 65px;
    font-family: $font-inter;

    @include text-overflow;
  }

  .iconCaretDown {
    width: 12px;
    height: 9px;
    position: absolute;
    top: 14px;
    right: 15px;
    fill: $color-white;
  }

  .dropdown {
    position: absolute;
    z-index: 2;
    top: 50px;
    left: -600px;
    padding: 0 12px 10px;
    color: $color-grey-1;
    background-color: $color-white;
    border: 2px solid transparent;
    box-shadow: $dropdown-shadow;

    @include primary-border-color();

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      $size: 16px;
      display: none;
      left: 655px;
      margin-left: math.div(-$size, 2);
      top: math.div(-$size, 2);
      width: $size;
      height: $size;
      transform: rotate(45deg);
      border: 2px solid transparent;
      background: $color-white;

      @include primary-border-color();
    }

    &:after {
      $size: 30px;

      top: 0;
      left: 655px;
      margin-left: math.div(-$size, 2);
      width: $size;
      height: math.div($size, 2);
      background: $color-white;
    }

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;
      padding: 9px 0;
      border-bottom: 2px solid #f0f0f0;

      .caption {
        font-size: 16px;
        line-height: 35px;
        color: $color-blue-dark;
      }

      // todo it's modal duplicate
      button.btnClose {
        $size: 21px;

        position: absolute;
        right: 20px;
        top: 12px;
        width: $size;
        height: $size;
        padding: 0;

        &:hover {
          opacity: 0.8;
        }

        .iconClose {
          width: $size;
          height: $size;
        }
      }
    }
  }
}

.savedSearch {
  position: relative;
  height: 36px;
  
  button {
    font-family: $font-inter;
  }

  .iconCaretDown {
    width: 12px;
    height: 9px;
    position: absolute;
    top: 14px;
    right: 9px;
  }

  .dropdown {
    $sizeArrow: 5px;

    position: absolute;
    margin-top: 5px;
    right: 0;
    width: 445px;
    border: 2px solid transparent;
    background: $color-white;
    box-shadow: $dropdown-shadow;
    border-radius: 2px;
    z-index: 99;
    border: 0 !important;

    @include primary-border-color();

    &:before,
    &:after {
      content: '';
      position: absolute;
      background: $color-dirty-white;
      display: none;
    }

    &:before {
      right: 40px;
      top: -3px;
      width: $sizeArrow;
      height: $sizeArrow;
      transform: rotate(45deg);

      @include primary-color-box-shadow();
    }

    &:after {
      height: $sizeArrow + 2;
      top: 0;
      width: 15px;
      right: 35px;
    } 

    .dropdownTitle {
      padding: 15px;
      background: $color-dirty-white;
      height: 45px;
      color: $color-blue-dark;
      font-size: 16px;
      line-height: 18px;
    }

    .dropdownBody {
      padding: 15px;
    }

    .inlineRadio {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }

    .row {
      margin: 5px 0 20px;
    }

    .buttons {
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 10px;
        width: 95px;
      }
    }

    .disabled {
      *,
      input,
      & {
        cursor: not-allowed;
        color: $color-grey;
      }
    }

    .flexInclude {
      display: flex;

      > span {
        margin-left: 5px;
      }
    }
  }
}

.clsSaveSearchPopupDropdown {
  border: 0;
  box-shadow: none !important;
  border-radius: 12px;
  transform: translateX(-50%);
  left: 50%;
  width: auto !important;
  right: unset !important;
  background: transparent !important;
}

.showAll {
  position: relative;
  
  button {
    font-family: $font-inter;
  }

  .iconCaretDown {
    width: 12px;
    height: 9px;
    position: absolute;
    top: 14px;
    right: 9px;
  }

  .dropdown {
    $sizeArrow: 5px;

    position: absolute;
    margin-top: 5px;
    right: -2px;
    width: 500px;
    border: 2px solid transparent;
    background: $color-white;
    box-shadow: $dropdown-shadow;
    border-radius: 2px;
    z-index: 9;

    @include primary-border-color();

    &:before,
    &:after {
      content: '';
      position: absolute;
      background: $color-dirty-white;
    }

    &:before {
      right: 40px;
      top: -3px;
      width: $sizeArrow;
      height: $sizeArrow;
      transform: rotate(45deg);

      @include primary-color-box-shadow();
    }

    &:after {
      height: $sizeArrow + 2;
      top: 0;
      width: 15px;
      right: 35px;
    }

    &.clsPopupDropdown{
      border: 0;
      box-shadow: none;
      background-color: transparent;
      border-radius: 0;
      &:before,
      &:after {
        content: '';
        position: absolute;
        background: transparent !important;
      }
    }

    .dropdownTitle {
      padding: 15px;
      background: $color-dirty-white;
      height: 45px;
      color: $color-blue-dark;
      font-size: 16px;
      line-height: 18px;
    }

    .dropdownBody {
      padding: 15px;
    }

    .inlineRadio {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }

    .row {
      margin: 5px 0 20px;
    }

    .buttons {
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 10px;
        width: 95px;
      }
    }

    .disabled {
      *,
      input,
      & {
        cursor: not-allowed;
        color: $color-grey;
      }
    }

    .flexInclude {
      display: flex;

      > span {
        margin-left: 5px;
      }
    }
  }
}

.savedSearchInfo {
  .header {
    display: flex;
    justify-content: space-between;
    margin-top: -13px;
    padding-bottom: 15px;
    height: 50px;
  }

  .headerTitle {
    color: $color-blue-dark;
    font-size: 16px;
    line-height: 18px;
    padding-top: 5px;

    @include text-overflow;
  }

  .tinyCaption {
    color: $color-grey-1;
    font-size: 13px;
    line-height: 18px;
    padding-top: 5px;
  }

  .map {
    margin: 30px auto 0;
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.togglePropertiesView {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  height: 100%;

  $color-grey: #b3b8bf;

  .item,
  .icon use {
    transition: all $transition-delay;
  }

  .icon use {
    fill: $color-grey;
  }

  .item {
    cursor: pointer;
    width: 67px;
    color: $color-grey;

    &.active,
    &:hover {
      color: $color-teal;
    }

    &:hover .icon use,
    &.active .icon use {
      fill: $color-teal;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  .iconPic {
    width: 24px;
    height: 18px;
  }

  .iconList {
    width: 30px;
    height: 17px;
  }

  .label {
    font-size: 12px;
    line-height: 14px;
  }
}
 

@media (min-width: 1440px) {
  body{
    .appHeader {  
      .bottom { 
        .row {  
          .col {
            width: 139px;
          }
        }
      }
    }
    .allSearches {
      height: 58px;
      .searchContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        .icon {
          height: 27px;
          width: 27px;
        }
        .label {
          text-align: center;
          font-family: $font-inter;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1024px) {
  body{
    .appHeader {  
      .bottom { 
        .row {  
          .col {
            width: 136px; 
          }
        }
      }
    }
    .allSearches {
      height: 58px;
      .searchContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        .icon {
          height: 25px;
          width: 27px;
        }
        .label {
          text-align: center;
          font-family: $font-inter;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}


@media only screen and (max-width: 1024px) and (min-width: 855px) {
  body {
    .allSearches {
      height: 58px;
      .searchContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        .icon {
          width: 18px;
          height: 18px;
        }
        .label {   
          text-align: center;
          font-family: $font-inter;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}

@media only screen and (max-width: 854px) and (min-width: 768px) {
  body {
    .allSearches {
      height: 58px;
      .searchContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        .icon {
          // width: 18px;
          // height: 18px;

        width: 14px;
        height: 14px;
        }
        .label {   
          text-align: center;
          font-family: $font-inter;
          // font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          font-size: 7px;
          font-weight: 400;
        }
      }
    }
  }
}

.allSearches {
  position: relative;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-width: 0;
  background: $color-white;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all $transition-delay;
  height: 58px;
  &.active {
    background-color: $color-orange;
    .label {
      color: $color-white !important;
    }
    &:hover {
      background-color: $color-orange !important;
    }
  }
  &:hover {
    background-color: #FFEBE1 !important;
  }

  &[disabled] {
    pointer-events: none;
    background: $color-grey-lighter;
  }
    .searchContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        width: 25px;
        height: 25px;
        width: 27px;
        flex-shrink: 0;
      }
      .label {
        color: $color-grey;
        font-size: 11px;
        font-weight: 600;
        text-align: center;
        font-family: $font-inter;
        font-style: normal;
        line-height: normal;
      }
    }
  }

  .root:not(.toTop) {
    position: absolute;
    right: 0;
    top: 100%;
    width: 565px;
    margin-top: 15px;
    padding-bottom: 22px;
    border: 2px solid transparent;
    background-color: $color-white;
    box-shadow: $dropdown-shadow;
    text-align: left;
    cursor: default;

    @include primary-border-color();

    &.addRoot {
      position: absolute;
      margin-top: -110px;
      left: -375px;
      width: 447px;
      padding-bottom: 0;
      z-index: 2;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 58px;
      background-color: $color-dirty-white;
    }

    &:before {
      $size: 16px;

      width: $size;
      height: $size;
      margin-top: math.div(-$size, 2);
      margin-right: math.div(-$size, 2);
      border: 2px solid transparent;
      transform: rotate(45deg);

      @include primary-border-color();
    }

    &:after {
      $size: 30px;

      width: $size;
      margin-right: math.div(-$size, 2);
      height: math.div($size, 2);
    }
  }
  
  .root.leftAlign:not(.toTop) {
    left: 0;
    &:before,
    &:after {
      left: 58px;
    }
  }

  .root.toTop {
    position: absolute;
    right: 0;
    top: 0;
    width: 565px;
    margin-bottom: 15px;
    padding-bottom: 22px;
    border: 2px solid transparent;
    background-color: $color-white;
    box-shadow: $dropdown-shadow;
    text-align: left;
    cursor: default;

    @include primary-border-color();

    &.addRoot {
      position: absolute;
      // margin-top: -110px;
      left: -375px;
      width: 447px;
      padding-bottom: 0;
      z-index: 2;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 58px;
      background-color: $color-dirty-white;
    }

    &:before {
      $size: 16px;

      width: $size;
      height: $size;
      margin-bottom: math.div(-$size, 2);
      margin-right: math.div(-$size, 2);
      border: 2px solid transparent;
      transform: rotate(-45deg);

      @include primary-border-color();
    }

    &:after {
      $size: 30px;

      width: $size;
      margin-right: math.div(-$size, 2);
      height: math.div($size, 2);
    }
  }

  .header {
    padding: 13px 5px;
    font-size: 12px;
    line-height: 14px;
    color: $color-blue-darkest;
    text-align: center;
  }

  .tableWrapper {
    overflow: auto;
    max-height: 470px;
  }

  .addHeader {
    padding: 12px 18px;
    background-color: $color-dirty-white;
    font-size: 16px;
    line-height: 18px;
    color: $color-blue-dark;
  }

  .dot {
    $size: 3px;

    display: inline-block;
    vertical-align: top;
    height: $size;
    width: $size;
    border-radius: 50%;
    background-color: $color-red-bright;
  }

  .assignSearch {
    top: -2px;
    font-size: 16px;
    line-height: 21px;
  }

  .assignSearch,
  .tableBtn {
    color: $color-blue-light;
    cursor: pointer;
  }

  .addBody {
    padding: 20px;
    font-size: 12px;
    color: $color-grey-1;
  }

  .caption {
    margin-bottom: 5px;
    color: $color-blue-darkest;
  }

  .selectContainer {
    margin-top: 18px;
  }

  .buttonsContainer {
    margin-top: 22px;
    text-align: right;

    button {
      margin-left: 13px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .inlineButton {
    display: inline-block;
    border: 0;
    background: none;
  }

  .noWrap {
    white-space: nowrap;
  }

  .details {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
  }

.searchInput {
  position: relative;

  .iconSearch {
    position: absolute;
    top: 5px;
    right: 8px;
    z-index: 3;
    display: flex;
    align-items: center;
    border-radius: 999px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover{
      background-color: #E9EBEE;
    }
    &::before{
      content: "";
      width: 1px;
      height: 24px;
      background-color: rgba(96, 106, 117, 0.6);
      left: -10px;
      position: absolute;
    }
    svg {
      width: 20px;
      height: 21px;
      fill: $color-orange;
    }
    .searchText {
      font-size: 14px;
      font-weight: 700;
      color: $color-orange;
      margin-left: 10px;
      font-family: $font-inter;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .search {
    height: 36px;
    min-width: 613px;
    width: 100%;
    border-radius: 999px;
    border: 1px solid rgba(96, 106, 117, 0.6);
    background: $color-white;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
    padding: 0;
    transition: all $transition-delay;
    color: $color-grey;
    font-size: 14px;
    z-index: 2;
    position: relative;
    overflow: hidden;
    input {
      font-family: $font-inter;
    }
    &:focus {
      background: $color-white;

      @include primary-color-box-shadow-inset();
    }

    :global {
      $Select-left-padding: 32px;

      .Select-control {
        border: 0;
        height: auto;
        color: $color-grey-1;
        padding-left: $Select-left-padding;

        .Select-value {
          left: $Select-left-padding !important;
        }

        .Select-input > input {
          width: auto !important;
          max-width: 230px;
        }
      }

      .Select-wrapper,
      .Select-placeholder {
        left: $Select-left-padding;
      }

      .Select-menu-outer {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid $color-blue-dark;
      }

      .Select-noresults {
        display: none;
      }

      .Select-arrow-zone {
        width: 5px;
      }
    }
  }
}


// responsive css start
@media (min-width: 768px) and (max-width: 1150px) {
  .searchInput {
    .search {
      min-width: 500px;
    }
  }
}

// responsive css end