@import "./../../app/mixins.scss";

:global {
  @import '~react-select/dist/react-select';

  .autocomplete-div-hide-on-empty {
    $sizeArrow: 5px;

    background: $color-white;
    min-height: 20px;
    border: 2px solid transparent;
    position: absolute;
    //overflow-y: auto;

    @include primary-border-color();

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      right: 40px;
      top: -3px;
      width: $sizeArrow;
      height: $sizeArrow;
      transform: rotate(45deg);
      background: $color-white;

      @include primary-color-box-shadow();
    }

    &:after {
      height: $sizeArrow+2;
      top: 0;
      width: 15px;
      right: 35px;
      background: $color-white;
    }
  }

  .react-autosuggest {
    &__suggestions-list {
      background: $color-white;
      margin: 0;
      padding: 10px;
    }

    &__suggestions-wrapper {
      height: 100%;
      max-height: 205px;
      margin-bottom: 5px;
      position: relative;
      overflow-y: auto;
    }

    &__suggestion {
      font-size: 12px;
      color: $color-dove-gray;
      display: block;
      margin: 0;
      padding: 12px 5px 10px;
      border: 1px solid $color-silver;
      border-top: none;

      &--first {
        border-top: 1px solid $color-silver;
      }

      &--highlighted {
        background: $color-alabaster;
      }

      &--accent {
        background: $color-alabaster;
        border: none;
      }
    }

    &__suggestion-wrapper {
      display: block;
    }
  }
}

.autosuggestWrapper {
  background: $color-white;
  width: 100%;
  height: 34px;

  input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 10px 15px;
    border-radius: 999px;
    border: none;
  }
}

.caption {
  color: $color-blue-dark;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  padding: 10px 10px 5px;

  .iconCaption {
    width: 25px;
    height: 26px;
    margin-right: 5px;
  }
}

button.btnClear {
  $size: 26px;
  display: block;
  width: $size;
  height: $size;
  position: absolute;
  top: 5px;
  right: 120px;
  z-index: 30;
  padding: 5px;
  border-radius: 999px;
  &:hover{
    background-color: #E9EBEE;
  }

  .iconClear {
    width: 12px;
    height: 12px;

    use {
      @include primary-fill-color();
    }
  }
}
